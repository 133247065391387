import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  InputAdornment,
  TextField,
  Button,
  LinearProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputLabel,
} from "@mui/material";
import AppHeader from "../../Layouts/AppHeader/AppHeader";
import SideBarNavigation from "../../Layouts/Sidebar/AppSidebar";
const tableHeaderCellStyle = {
  fontWeight: 600,
  fontSize: "14px",
  color: "#455A64",
  font: "Rubik",
};

const tableBodyCellStyle = {
  fontWeight: 400,
  fontSize: "14px",
  color: "#A4A2A2",
  font: "Rubik",
};
const dummyData = [
  {
    serialno: "1",
    payoutfile: "payout_2024_001.csv",
    type: "Regular",
    status: "Completed",
    payouterrorfile: "payout_errors_2024_001.csv",
    lastupdatedtime: "2024-04-01T10:23:45",
  },
  {
    serialno: "2",
    payoutfile: "payout_2024_002.csv",
    type: "Bonus",
    status: "Pending",
    payouterrorfile: "-",
    lastupdatedtime: "2024-04-01T09:15:30",
  },
  {
    serialno: "3",
    payoutfile: "payout_2024_003.csv",
    type: "Regular",
    status: "Failed",
    payouterrorfile: "payout_errors_2024_003.csv",
    lastupdatedtime: "2024-04-01T11:45:22",
  },
];

export default function PayoutRelease() {
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const [totalCount, setTotalCount] = useState<any>();
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };
  return (
    <>
      <div className="text-sm">
        <AppHeader />
      </div>
      <div className="flex">
        <SideBarNavigation />
        <div className="w-full md:w-4/5 ml-4">
          <div>
            <div>
              <p className="mr-2 mt-4 text-2xl font-semibold">
                Import Pilot Payout Release Sheet
              </p>
            </div>
            <div className="border-0 rounded-3xl bg-white mt-6">
              <div className="">
                <div className="flex pl-6">
                  {/* <Typography>Import File:</Typography> */}
                  <p className="mr-2 mt-2 font-semibold">Import File:</p>
                  <TextField
                    type={"file"}
                    size="small"
                    required
                    inputProps={{ accept: ".xlsx, .xls, .csv" }}
                  />
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: "#00CD5B",
                      px: 5,
                      ml: 2,
                      "&:hover": {
                        backgroundColor: "#63dd99",
                      },
                    }}
                    variant="contained"
                  >
                    Import
                  </Button>
                </div>
              </div>
              {loading ? (
                <LinearProgress
                  className="pl-6 pr-6"
                  sx={{
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00CD5B",
                    },
                  }}
                />
              ) : (
                <>
                  <TableContainer
                    style={{ width: "100%", overflowX: "auto" }}
                    className="pl-5 pr-5 pt-5"
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={tableHeaderCellStyle}>#</TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            Payout File
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            Type
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            Status
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            Payout Error File
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            Last Updated Time
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dummyData.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell style={tableBodyCellStyle}>
                              {data.serialno}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle}>
                              {data.payoutfile}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle}>
                              {data.type}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle}>
                              {data.status}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle}>
                              {data.payouterrorfile}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle}>
                              {data.lastupdatedtime}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Snackbar
                    open={showSnackbar}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert
                      sx={{ width: "100%" }}
                      onClose={handleClose}
                      severity="error"
                    >
                      No data found
                    </Alert>
                  </Snackbar>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
