import { Tooltip } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

const AppHeader: React.FC = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  return (
    <nav className="bg-black p-6 flex justify-between items-center sticky top-0 z-50">
      <div className="flex items-center">
        <Link to="/" className="font-bold text-xl text-white">
          <img src="/assets/logo_new.png" className="h-6" alt="" />
        </Link>
      </div>
      <div className="flex items-center">
        <Link to="/">
          <Tooltip title="Logout" onClick={handleLogout}>
            <img src="/assets/logout.png" alt="" className="h-6" />
          </Tooltip>
        </Link>
      </div>
    </nav>
  );
};

export default AppHeader;
