import "./Sidebar.css";

import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

interface MenuItem {
  route: string;
  icon: string;
  label: string;
}

const menuItems: MenuItem[] = [
  {
    route: "/dailyPayout",
    icon: "/assets/icons/Dashboard-icon.svg",
    label: "Pilot Daily Payout ",
  },
  {
    route: "/multiclientPayout",
    icon: "/assets/icons/Order-Management.svg",
    label: "Pilot Multi-Client Payout ",
  },

  {
    route: "/fleetRental",
    icon: "",
    label: "Fleet Rental",
  },
  // {
  //   route: "/payoutRelease",
  //   icon: "/assets/icons/Create-order.svg",
  //   label: "Pilot Payout Release",
  // },
  // {
  //   route: "/",
  //   icon: "/assets/icons/Track-order.svg",
  //   label: "Payout Status",
  // },
];

const SideBarNavigation: React.FC = () => {
  const navigateTo = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null);

  const handleMenuItemClick = (route: string) => {
    navigateTo(route);
  };

  useEffect(() => {
    setActiveMenuItem(window.location.pathname);
  }, [window.location.pathname]);

  const getMenuStyles = (route: string) =>
    [
      "flex",
      "items-center",
      "justify-center",
      "gap-2",
      "text-[#ffffff]",
      "rounded-[4px]",
      "px-1",
      "py-3",
      "ml-2",
      "mr-2",
      "hover:bg-[#63dd99] hover:text-[#ffffff]",
      activeMenuItem === route ? "bg-[#23432g] text-[#ffffff]" : "",
      "cursor-pointer",
      "bg-[#00CD5B]",
      route === activeMenuItem && "bg-[#0079FF] text-white",
      `hover:bg-[#0079FF] hover:text-white`,
    ].join(" ");
  return (
    <div
      style={{
        boxShadow: "0px 7px 29px 0px #64646F1A",
        height: "100vh",
      }}
      className="text-[#020D53] bg-black text-[14px] pt-4 font-medium px-1 font-inter shadow-md shadow-[#64646F1A] sticky top-0"
    >
      <div>
        <div className="font-semibold text-2xl text-left m-2 mb-8 cursor-pointer">
          {/* <a href="/home">
              <img src="/assets/icons/zyppItLogo.png" alt="zyppIt Logo" />
            </a> */}
        </div>
        <ul className="flex flex-col gap-4 pb-32">
          {menuItems.map((menuItem) => (
            <li
              key={menuItem.route}
              className={getMenuStyles(menuItem.route)}
              onClick={() => handleMenuItemClick(menuItem.route)}
            >
              <img
                className={`h-4 ${
                  menuItem.route === activeMenuItem
                    ? "filter-white"
                    : "filter-[#0079FF]"
                } hover:filter-white`}
                src={menuItem.icon}
                alt=""
              />
              {menuItem.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideBarNavigation;
