import {
  DownloadTemplateResponse,
  MediaUploadFileIdResponse,
} from "../@types/DRO/MediaUploadResponse";
import axios, { AxiosInstance, AxiosResponse } from "axios";

import { DailyPayoutPropsArray } from "../@types/DRO/DailyPayoutResponse";
import { RentalFleetExportResponse } from "../@types/DRO/FleetRentalResponse";

export class MediaUploadApi {
  public static api = new MediaUploadApi();
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      // baseURL: process.env.REACT_APP_ZYPP_UPLOAD_API_URL,
      baseURL: "https://pre-micro.bcykal.com/mobycy/payout-manager",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  getToken() {
    return localStorage.getItem("token");
  }
  mediaUploadFileId(
    file: File
  ): Promise<AxiosResponse<MediaUploadFileIdResponse>> {
    const formData = new FormData();
    formData.append("file", file);

    return this.instance.post<MediaUploadFileIdResponse>(
      "/media/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
  }
  async uploadFileAndPayoutSheet(file: File, type: number): Promise<void> {
    try {
      const uploadResponse = await this.mediaUploadFileId(file);
      const fileId = uploadResponse.data.fileId;
      await this.instance.post<any>(
        `/pilot/payout/files?fileId=${fileId}&type=${type}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      );
      console.log("Payout sheet uploaded successfully.");
    } catch (error) {
      console.error("Error uploading payout sheet:", error);
    }
  }
  async uploadFleetRentalPayout(file: File): Promise<void> {
    try {
      const uploadResponse = await this.mediaUploadFileId(file);
      const fileId = uploadResponse.data.fileId;
      await this.instance.post<any>(
        `/pilot/payout/rental/files?file_id=${fileId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      );
      console.log("Payout sheet uploaded successfully.");
    } catch (error) {
      console.error("Error uploading payout sheet:", error);
    }
  }

  async getFileUrl(fileId: number): Promise<string> {
    try {
      const response = await this.instance.get(`/media/get`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.getToken()}`,
        },
        params: {
          fileId: fileId,
        },
      });
      return response.data.fileUrl;
    } catch (error) {
      console.error("Error fetching file URL:", error);
      throw error;
    }
  }

  async downloadTemplate(templateType: string): Promise<string> {
    try {
      const response = await this.instance.get<DownloadTemplateResponse>(
        `/download-template`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
          params: {
            template_type: templateType,
          },
        }
      );
      return response.data.data.downloadLink;
    } catch (error) {
      console.error("Error downloading template:", error);
      throw error;
    }
  }

  async getRentalFleetExport(
    merchantIds: string,
    cityCodes: string
  ): Promise<RentalFleetExportResponse> {
    try {
      const response = await this.instance.get<RentalFleetExportResponse>(
        `/rental-export`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
          params: {
            cityCodes: cityCodes,
            merchantIds: merchantIds,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching rental fleet export data:", error);
      throw error;
    }
  }
}
