import {
  Alert,
  AlertColor,
  Button,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import AppHeader from "../../Layouts/AppHeader/AppHeader";
import { DailyPayoutPropsArray } from "../../../@types/DRO/DailyPayoutResponse";
import { MediaUploadApi } from "../../../api/MediaUploadApi";
import SideBarNavigation from "../../Layouts/Sidebar/AppSidebar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ZyppUploadApi } from "../../../api/ZyppUpload";

const tableHeaderCellStyle = {
  fontWeight: 600,
  fontSize: "14px",
  color: "#455A64",
  font: "Rubik",
};

const tableBodyCellStyle = {
  fontWeight: 400,
  fontSize: "14px",
  color: "#A4A2A2",
  font: "Rubik",
};

export default function MultiClientPayout() {
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const [totalCount, setTotalCount] = useState<any>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [orderBy, setOrderBy] = useState<"asc" | "desc" | undefined>(undefined);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    AlertColor | undefined
  >("info");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      if (/\.(xlsx|xls)$/i.test(file.name)) {
        setSelectedFile(file);
        setSnackbarMessage("");
        setShowSnackbar(false);
      } else {
        setSnackbarMessage("Please select a .xlsx or .xls file.");
        setSnackbarSeverity("error");
        setShowSnackbar(true);
        setSelectedFile(null);
      }
    }
  };

  const handleImport = async () => {
    if (!selectedFile) {
      setSnackbarMessage("Please select a file first.");
      setSnackbarSeverity("warning");
      setShowSnackbar(true);
      return;
    }
    if (!/\.(xlsx|xls)$/i.test(selectedFile.name)) {
      setSnackbarMessage(
        "Invalid file format. Please select a .xlsx or .xls file."
      );
      setSnackbarSeverity("error");
      setShowSnackbar(true);
      return;
    }
    setLoading(true);
    try {
      await MediaUploadApi.api.uploadFileAndPayoutSheet(selectedFile, 2);
      setSnackbarMessage("File imported successfully.");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      window.location.reload();
    } catch (error) {
      console.error("Error during the import process:", error);
      setSnackbarMessage("Failed to import file.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const [multiClientPayout, setMultiClientPayout] = useState<
    DailyPayoutPropsArray[]
  >([]);

  useEffect(() => {
    setLoading(true);
    ZyppUploadApi.api
      .multipleDailyPayoutList(page, pageSize, sortBy, orderBy)
      .then((data) => {
        setMultiClientPayout(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch daily payout list:", error);
        setLoading(false);
        setShowSnackbar(true);
      });
  }, [page, pageSize, sortBy, orderBy]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const [fileUrls, setFileUrls] = useState<{ [key: number]: string }>({});
  const [errorFiles, setErrorFiles] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    const fetchFileUrls = async () => {
      const urls: { [key: number]: string } = {};
      for (const data of multiClientPayout) {
        try {
          const url = await MediaUploadApi.api.getFileUrl(data.payoutFile);
          urls[data.payoutFile] = url;
        } catch (error) {
          console.error(
            "Error fetching file URL for fileId:",
            data.payoutFile,
            error
          );
        }
      }
      setFileUrls(urls);
    };

    if (multiClientPayout.length > 0) {
      fetchFileUrls();
    }
  }, [multiClientPayout]);

  useEffect(() => {
    const fetchErrorFileUrls = async () => {
      const urls: { [key: string]: string } = {};
      for (const data of multiClientPayout) {
        if (typeof data.payoutFileError !== "undefined") {
          try {
            const errorFileUrl = await MediaUploadApi.api.getFileUrl(
              data.payoutFileError
            );
            urls[data.payoutFileError] = errorFileUrl;
          } catch (error) {
            console.error(
              "Error fetching file URL for fileId:",
              data.payoutFileError,
              error
            );
          }
        }
      }
      setErrorFiles(urls);
    };

    if (multiClientPayout.length > 0) {
      fetchErrorFileUrls();
    }
  }, [multiClientPayout]);

  useEffect(() => {
    const refreshPayoutFiles = async () => {
      try {
        await ZyppUploadApi.api.payoutFileRefresh(2);
        console.log("Payout files refreshed successfully.");
      } catch (error) {
        console.error("Failed to refresh payout files:", error);
      }
    };

    refreshPayoutFiles();
  }, []);

  return (
    <>
      <div className="text-sm">
        <AppHeader />
      </div>
      <div className="flex">
        <SideBarNavigation />
        <div className="w-full md:w-4/5 ml-4">
          <div>
            <div>
              <p className="mr-2 mt-4 text-2xl font-semibold">
                Import Pilot Multi-Client Daily Sheet
              </p>
            </div>
            <div className="border-0 rounded-3xl bg-white mt-6">
              <div className="">
                <div className="flex pl-6">
                  {/* <Typography>Import File:</Typography> */}
                  <p className="mr-2 mt-2 font-semibold">Import File:</p>
                  <TextField
                    type={"file"}
                    size="small"
                    required
                    inputProps={{
                      accept: ".xlsx, .xls,",
                    }}
                    onChange={handleFileChange}
                  />
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: "#00CD5B",
                      px: 5,
                      ml: 2,
                      "&:hover": {
                        backgroundColor: "#63dd99",
                      },
                    }}
                    variant="contained"
                    onClick={handleImport}
                  >
                    Import
                  </Button>
                </div>
              </div>
              <div className="ml-8">
                <Button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font"
                  sx={{
                    mt: 3,
                    mr: 97,
                  }}
                  variant="contained"
                  onClick={() => {
                    const link = document.createElement("a");
                    link.href = `${process.env.PUBLIC_URL}/template-multiclient.xls`;
                    link.setAttribute("download", "template-multiclient.xls");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                >
                  Download Template
                </Button>
              </div>
              {loading ? (
                <LinearProgress
                  className="pl-6 pr-6"
                  sx={{
                    backgroundColor: "white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00CD5B",
                    },
                  }}
                />
              ) : (
                <>
                  <TableContainer
                    style={{ width: "100%", overflowX: "auto" }}
                    className="pl-5 pr-5 pt-5"
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={tableHeaderCellStyle}>
                            #ID
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            Payout File
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            Type
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            Status
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            Payout Error File
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            Merchant Type
                          </TableCell>
                          <TableCell style={tableHeaderCellStyle}>
                            Last Updated Time
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {multiClientPayout.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell style={tableBodyCellStyle}>
                              {data.id}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle}>
                              {fileUrls[data.payoutFile] ? (
                                <a
                                  href={fileUrls[data.payoutFile]}
                                  className="text-blue-500 font-semibold"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {data.payoutFile}
                                </a>
                              ) : (
                                data.payoutFile
                              )}
                            </TableCell>
                            {/* <TableCell style={tableBodyCellStyle}>
                              {data.payoutFile}
                            </TableCell> */}
                            <TableCell style={tableBodyCellStyle}>
                              {data.payoutTypeDescription}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle}>
                              {data.statusDescription}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle}>
                              {data.payoutFileError &&
                              errorFiles[data.payoutFileError] ? (
                                <a
                                  href={errorFiles[data.payoutFileError]}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-red-900 font-semibold"
                                >
                                  {data.payoutFileError}
                                </a>
                              ) : (
                                "N/A"
                              )}
                              {/* {data.payouterrorfile} */}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle}>
                              {data.merchantTypeDescription}
                            </TableCell>
                            <TableCell style={tableBodyCellStyle}>
                              {data.modifiedTime}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>{" "}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    count={2000}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={(event) => {
                      setPageSize(parseInt(event.target.value, 10));
                      setPage(0);
                    }}
                  />
                  <Snackbar
                    open={showSnackbar}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert
                      sx={{ width: "100%" }}
                      onClose={handleClose}
                      severity={snackbarSeverity}
                    >
                      {snackbarMessage}
                    </Alert>
                  </Snackbar>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
